import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {SwitchModeButton} from "./SwitchModeButton.jsx";
import SettingsIcon from "@mui/icons-material/Settings.js";
import AppBar from "@mui/material/AppBar";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Avatar, IconButton, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import {UserMenu} from "./Menus/UserMenu.jsx";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';
import SvgIcon from "@mui/material/SvgIcon";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from '@mui/material/styles';

function NavigationComponent() {
    return (
        <nav>
            <Link
                variant="button"
                color="text.primary"
                href="#"
                sx={{my: 1, mx: 1.5}}
            >
                Features
            </Link>
            <Link
                variant="button"
                color="text.primary"
                href="#"
                sx={{my: 1, mx: 1.5}}
            >
                Enterprise
            </Link>
            <Link
                variant="button"
                color="text.primary"
                href="#"
                sx={{my: 1, mx: 1.5}}
            >
                Support
            </Link>
        </nav>
    )
}

function HomeIcon(props) {
    // TODO: Should be the logo instead of the home icon
    const navigate = useNavigate();
    const goToHome = () => {
        navigate('/');
        // handleClose();
    };
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{mr: 2}}
                onClick={goToHome} // Open the menu on button click
            >
                <SvgIcon {...props}>
                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
                </SvgIcon>
            </IconButton>
        </Box>
    )
}

export function CBusAppBar() {
    const theme = useTheme();
    const [backgroundColor, setBackgroundColor] = useState('');

    function hexToRgb(hex) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    useEffect(() => {
        const rgb = hexToRgb(theme.palette.background.default);
        // if (rgb) {
        //     setBackgroundColor(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7)`);
        // }
    }, [theme])
    // const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        isAuthenticated,
        isLoading,
        user,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
    } = useAuth0();
    const handleUserDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };
    if (!isLoading) {
        if (isAuthenticated) {
            let getToken;
            //TODO Make configureable
            try {
                getToken = getAccessTokenSilently({
                    audience: 'https://cbusha.com',
                    scope: 'openid profile email offline_access',
                });
            } catch (e) {
                getToken = getAccessTokenWithPopup({
                    audience: 'https://cbusha.com',
                    scope: 'openid profile email offline_access',
                });
            }
            getToken.then((token) => {
                if (token) {
                    axios.get("/services/user/stash", {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }).then((response) => {
                        console.log(response);
                    }).catch((error) => {
                        console.error(error);
                    })
                }
            })

        }
    }
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                // position="sticky"
                elevation={0}
                sx={{
                    backdropFilter: "blur(10px)", // Add this line
                    backgroundColor: 'transparent', // And this line
                }}
            >
                <Toolbar sx={{
                    alignItems: 'stretch',
                }}>
                    <HomeIcon></HomeIcon>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                            CBus HA
                        </Typography>
                    </Box>
                    {isAuthenticated ? <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <SwitchModeButton/>
                    </Box> : <Box/>}
                    {/*<Button variant="outlined">Outlined</Button>*/}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        {/*TODO*/}
                        {/*<NavigationComponent/>*/}
                        {isAuthenticated ? <SettingsIcon
                            sx={{mx: 1}}
                        ></SettingsIcon> : <Box/>}
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mx: 1}}
                            onClick={handleUserDropdown} // Open the menu on button click
                        >
                            {isLoading ? (
                                <Box/>
                            ) : (
                                [
                                    isAuthenticated ? (
                                        <Avatar
                                            alt="User Avatar"
                                            src={user.picture}
                                            key="user-avatar"
                                        />
                                    ) : (
                                        <Avatar
                                            alt="User Avatar"
                                            src=""
                                            key="user-avatar"
                                            sx={{backgroundColor: 'black'}}
                                        >
                                            <PersonIcon key="person-icon"/>
                                        </Avatar>
                                    )
                                ]
                            )}

                        </IconButton>
                    </Box>
                </Toolbar>
                <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}/>
            </AppBar>
        </React.Fragment>
    )
}