import React from "react";
import {Box, Typography} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export function Dashboard() {
    return (
        <React.Fragment>
            <Box
                sx={{
                    backgroundImage: `url("https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '100%'
                }}
            >
                <AppBar
                    elevation={0}
                    position={"relative"}
                    sx={{ // Add this line
                        backgroundColor: 'transparent', // And this line
                    }}
                >
                    <Toolbar sx={{
                        marginTop: '3px'
                    }}>
                    </Toolbar>
                </AppBar>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '8px',
                    width: '100%',
                }}>
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                        variant="h1"
                    >
                        Dashboard
                    </Typography>
                </Box>

            </Box>
        </React.Fragment>
    );
}