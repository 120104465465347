import {Box, Switch, useTheme} from "@mui/material";
import React from 'react';
import {ThemeToggleContext} from "../ThemeToggleContext.mjs";

export const SwitchModeButton = () => {
    const theme = useTheme();
    const toggleTheme = React.useContext(ThemeToggleContext);
    const isDarkMode = theme.palette.mode === 'dark';

    const handleToggle = () => {
        toggleTheme();
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <span>{theme.palette.mode}</span>
            <Switch
                checked={isDarkMode}
                onChange={handleToggle}
                inputProps={{'aria-label': 'controlled'}}
            />
        </Box>
    );
};