import {createTheme} from "@mui/material";

// interface MyPaletteOptions extends PaletteOptions {
//     dark?: Partial<PaletteOptions>;
// }

export const myThemeOptions = {
    palette: {
        mode: "dark",
        // primary: {
        //     main: '#1FC8B9',
        //     dark: '#14A996',
        // },
        // secondary: {
        //     main: '#57ECE6',
        //     dark: '#0F6E60',
        // },
        // background: {
        //     default: '#6AD6CD',
        //     paper: '#086A5D',
        // },
        // dark: {
        //     background: {
        //         default: '#074E40',
        //         paper: '#424242',
        //     },
        // },
    },
};

export const createMyTheme = (options) => createTheme(options);