import React, {useState} from 'react';
import {Box, Button, Card, CardContent, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';

// Function to handle the toggle


const ScrollableBox = styled(Box)({
    overflow: 'auto',
    height: '100%', // Adjusted to take the full height of its parent
    width: '100%', // Take the full width of its parent
});

const CenteredBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    width: '100%', // Take the full width of its parent
});

const HeaderTypography = styled(Typography)({
    backgroundColor: '#157265',
    color: 'white',
    padding: '10px',
});

const MainCardContent = styled(CardContent)({
    backgroundColor: '#5abfb8', // Adjust this color as needed
});

// TODO: Create a contact form with the following fields:
// - Datetime range
// - Address
// - Description
// - Email
// The DateTimeRangePicker component from @mui/lab is a good starting point for the datetime range field.
// The TextField component from @mui/material is a good starting point for the other fields.
// The Button component from @mui/material is a good starting point for the submit button.
export const ContactForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        datetimeRange: '',
        location: '',
        description: '',
        contactinfo: ''
    });
    const [contactMethod, setContactMethod] = React.useState('email');

    const handleContactMethodChange = (event, newMethod) => {
        if (newMethod !== null) { // Prevent deselecting both options
            setContactMethod(newMethod);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform validation and submit the form data to the server
        // const formData = new FormData();
        // formData.append('name', formData.);
        // formData.append('address', e.target.address.value); // Assuming you want to send 'address'
        // formData.append('description', e.target.description.value);
        // formData.append('contactInfo', e.target[contactMethod].value); // 'email' or 'phone'
        const body = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&');
        console.log("The body is: " + body);
        fetch('/services/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        })
            .then(() => navigate('/'))
            .catch((error) => {
                // TODO: Handle error
                console.error(error);
            });
    };

    return (
        <ScrollableBox>
            <CenteredBox>
                <Card sx={{minWidth: 275}}>
                    <HeaderTypography variant="h4" gutterBottom>
                        Contact Form
                    </HeaderTypography>
                    <MainCardContent>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name="name"
                                label="Who are you?"
                                fullWidth
                                value={formData.name}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="location"
                                label="Where is the gig?"
                                fullWidth
                                value={formData.address}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="description"
                                label="What can we do for you?"
                                fullWidth
                                multiline
                                rows={4}
                                value={formData.description}
                                onChange={handleChange}
                                margin="normal"
                            />

                            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                                <ToggleButtonGroup
                                    value={contactMethod}
                                    exclusive
                                    onChange={handleContactMethodChange}
                                    aria-label="contact method"
                                >
                                    <ToggleButton value="email" aria-label="email">
                                        Email
                                    </ToggleButton>
                                    <ToggleButton value="phone" aria-label="phone">
                                        Phone
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {contactMethod === 'email' ? (
                                    <TextField
                                        name="contactInfo"
                                        label="Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                        margin="normal"
                                        style={{flex: 1, marginLeft: 8}}
                                    />
                                ) : (
                                    <TextField
                                        name="contactInfo"
                                        label="Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        margin="normal"
                                        style={{flex: 1, marginLeft: 8}}
                                    />
                                )}
                            </Box>
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </form>
                    </MainCardContent>
                </Card>
            </CenteredBox>
        </ScrollableBox>
    );
};